import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="content">
          <section className="dark hero">
            <div className='section-content'>
              <div className="site-heading">
                <h1>Normark IO </h1>
              </div>
              <p className="ingress">Make the right digital products, tools and services for your business, employees, customers, and the world.</p>
            </div>
          </section>
          <section className='light'>
            <div className='section-content'>
              <h2>About me</h2>
              <p>I am Runar Normark, an UX & Service Designer for hire. I bring a unique blend of creativity, analytical thinking, and a deep understanding of human behavior to your projects. My primary focus is on designing for complex domains and developing outstanding digital work tools and self-service solutions. Over the last 20 years, I have participated in over 30 projects for organizations in the public, private, and non-profit sectors.</p>
              <p>What sets me apart? I believe it is my background in cognitive psychology and human factors that informs every design decision, ensuring your product truly resonates with users. I am a complexity specialist that tackle and reduce risks in complex projects, simplifying tasks like collaboration and decision making in military operations, tax filing, accounting, and writing wills. I handle the entire process, from strategy and verifying user needs to prototyping and implementation. I have a proven track record of helping orgnanizations improve their product development processes.</p>
              <p>Are you ready to collaborate? My expertise can benefit you if you need to:</p>
              <ul>
                <li>Combine UX design with agile development</li>
                <li>Find a lead or to fill a senior UX design role</li>
                <li>Define the problem and build the right solution</li>
                <li>Understand the needs and journeys of your users</li>
              </ul>
              <p>Let's discuss how I can help your products, tools and services deliver value across your business, employees, customers, and the world.</p><p> Read more and reach out on <a href="https://www.linkedin.com/in/runarnormark/">LinkedIn</a> or <a href="mailto:runar@normark.io">email me</a>.</p>
            </div>
          </section>
          <section className='dark'>
            <div className='section-content'>
              <h2>Collaborators</h2>
              <ul>
                <li><a href="https://blankstudio.no">Blank Studio</a></li>
                <li><a href="https://brainbase.no">BrainBase</a></li>
                <li><a href="https://eworkgroup.no">Ework</a></li>
                <li><a href="https://folq.no">Folq</a></li>
                <li><a href="https://hoioslo.no">Hoi!</a></li>
                <li><a href="https://nbeat.no">Northern Beat</a></li>
                <li><a href="https://studiolarsen.no">Studio Larsen</a></li>
                <li><a href="https://www.sealedit.no">Sealedit</a></li>
                <li><a href="https://testamentert.no">Testamentert</a></li>
                <li><a href="https://www.thoughtleader.global/">Thought Leader Global Media</a></li>
              </ul>
              <h2>Customers</h2>
              <ul>
                <li><a href="https://geomatikk.no">Geomatikk</a></li>
                <li><a href="https://intro.africa">Intro Africa</a></li>
                <li><a href="https://monter.no">Montér</a></li>
                <li><a href="https://holbergpsykologene.no">Psykologene på Holbergs plass</a></li>
                <li><a href="https://remarkable.com">reMarkable</a></li>
                <li><a href="https://siglarcarbon.com">Siglar Carbon</a></li>
                <li><a href="https://ssb.no">SSB (Statistics Norway)</a></li>
                <li><a href="https://udir.no">Utdanningsdirektoratet (The Norwegian Directorate for Education and Training)</a></li>
              </ul>
              
              <p className="foot"><small>
                Copyright © 2020 <a href="https://normark.io">Normark IO AS</a>. Org.nr: <a href="https://w2.brreg.no/enhet/sok/detalj.jsp?orgnr=921403593">921 403 593</a></small>
              </p>
            </div>
          </section>
        </div>
        <div className='scroll'>
          <span>↓</span>
        </div>
      </header>
    </div>
  );
}

export default App;
